import { graphql, useStaticQuery } from "gatsby"
import { PaddedBannerDiv, CtaButton } from "../styles/styles"
import React from "react"

const PaddedBanner = ({ siteTitle, link }) => {

  const data = useStaticQuery(graphql`
    query BannerImg {
      image: file(relativePath: {eq: "banner-crop.jpg"}) {  
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      video: file(relativePath: {eq: "banner-vid.mp4"}, absolutePath: {}) {
        publicURL
      }
    }
  `)

  return (
    <PaddedBannerDiv>
      <div>
        <video autoPlay={true} muted={true} loop={true}>
          <source src={data.video.publicURL} type="video/mp4"/>
        </video>
        <div>
          <div>
            <h1>MTraining Solutions Limited</h1>
            <h2>Making the most of human talent and potential.</h2>
          </div>
          <CtaButton to={link} style={{
            fontSize:'1.3rem',
            marginTop:'30px',
            padding:'20px 35px'
          }}>Contact Us</CtaButton>
        </div>
      </div>
    </PaddedBannerDiv>
  )
} 

export default PaddedBanner
